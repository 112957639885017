if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, argument) {
    argument = argument || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(argument, this[i], i, this);
    }
  };
}

function offset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

(function($) {
    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;
    var body = document.body;

    var header = document.getElementById('header');
    var headerHeight = header.clientHeight;;

    var video = document.getElementById('video');

    if (video && windowWidth > 992) {
        video.innerHTML = '<source src="' + video.dataset.mp + '" type="video/mp4" /><source src="' + video.dataset.webm + '" type="video/webm" />';
        video.play();
    }

    window.addEventListener('load', function() {
        headerHeight = header.clientHeight;
    });

    function homepage(){

        function timeline(){
            $("#timeline-main").owlCarousel({
                autoWidth: true,
                nav: false,
                dots: false,
            });

            owl = $("#timeline-main").owlCarousel();

            $("#timeline-nav .prev").click(function () {
                owl.trigger('prev.owl.carousel');
            });

            $("#timeline-nav .next").click(function () {
                owl.trigger('next.owl.carousel');
            });
        }

        timeline();
    }


    function presentationTile(){
        $(".presentation-tile .tile-images .slider").owlCarousel({
            items: 1,
            nav: false,
            dots: false,
        });

        owl = $(".presentation-tile .tile-images .slider").owlCarousel();

        $(".presentation-tile .tile-images .arrow-prev").click(function () {
            owl.trigger('prev.owl.carousel');
        });

        $(".presentation-tile .tile-images .arrow-next").click(function () {
            owl.trigger('next.owl.carousel');
        });
    }

    function pageNav(){
        var pageNavItems = $(".page-nav .page-nav-items");
        var width;

        pageNavItems.on('initialized.owl.carousel', function(event) {

            width = $('.page-nav .owl-stage').width();
            $('.page-nav-items').width(width);
        });

        pageNavItems.owlCarousel({
            autoWidth: true,
            dots: false,
            margin: 15,
            nav:true,
            navText: [
              "<i class='icon-left-triangle'></i>",
              "<i class='icon-right-triangle'></i>"
              ],
        });

        var stickyPageNav = document.querySelector('.page-nav-sticky');

        if (! stickyPageNav) {
            return false;
        }

        var stickyPageNavOffest = stickyPageNav.getBoundingClientRect().top + window.pageYOffset;
        var stickyPageNavHeight = stickyPageNav.getBoundingClientRect().height;
        var topOffset = 0;

        if ($('.menu-toggle').is(':visible') ){
            topOffset = headerHeight;
        }

        window.addEventListener('scroll', function() {

            if (window.pageYOffset + topOffset >= stickyPageNavOffest) {
                body.style.paddingTop = stickyPageNavHeight + 'px';
                stickyPageNav.style.top = topOffset + 'px';
                stickyPageNav.classList.add('fixed');
            }else{
                body.style.paddingTop = 0;
                stickyPageNav.classList.remove('fixed');
            }
        });

        function scrollSpy() {
            'use strict';

            var section = document.querySelectorAll(".presentation-tile");
            var sections = [];
            var i = 0;
            var activeLinks;
            Array.prototype.forEach.call(section, function(e) {
                sections[e.id] = e.offsetTop;
            });

            window.onscroll = function() {
                var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
                for (i in sections) {
                    if (sections[i] <= scrollPosition) {
                        activeLinks = document.querySelector('.page-nav-items a.active');
                        if (activeLinks) {
                            activeLinks.setAttribute('class', ' ');
                        }

                        document.querySelector('a[href*=' + i + ']').setAttribute('class', 'active');
                    }
                }
            };
        }
        scrollSpy();
    }

    function timetable(){

        if ( ! document.getElementById('timetable')) {
            return false;
        }

        var timetableHeading = document.getElementById('timetable-heading'),
            timetableHeadingOffset = timetableHeading.getBoundingClientRect().top + window.pageYOffset,
            timetableHeadingHeight = timetableHeading.getBoundingClientRect().height,
            timetableWrap = document.getElementById('timetable-wrap'),
            timetableTable = document.getElementById('timetable-table'),
            timetableTableOffsetTop = offset(timetableTable).top,
            timetableTableHeight = timetableTable.getBoundingClientRect().height,
            timetableTableOffsetBottom = timetableTableOffsetTop + timetableTableHeight,
            timetableCellWidth = Math.round(document.querySelectorAll('.timetable-cell:nth-child(2)')[0].getBoundingClientRect().width),
            timetableArrow = document.getElementById('timetable-arrow'),
            timetableArrowOffset = offset(timetableArrow).top,

            marginTop = 0;

        if (window.innerWidth < 992) {
            marginTop = 0;
        }

        function animate(){

            timetableHeading.style.left = timetableWrap.scrollLeft * -1 + 13.5 + 'px';
        }

        timetableWrap.addEventListener('scroll', function() {
            window.requestAnimationFrame(animate);
        });

        var topOffset = 0;

        if ($('.menu-toggle').is(':visible') ){
            topOffset = headerHeight;
        }

        window.addEventListener('scroll', function() {

            if (window.pageYOffset + topOffset >= timetableHeadingOffset) {
                body.style.paddingTop = timetableHeadingHeight + 'px';
                timetableHeading.style.top = topOffset + 'px';
                timetableHeading.classList.add('fixed');
            }else{
                body.style.paddingTop = 0;
                timetableHeading.classList.remove('fixed');
            }

            if (window.pageYOffset + .45 * windowHeight >= timetableArrowOffset && window.pageYOffset < timetableTableOffsetBottom - .9 * windowHeight) {
                timetableArrow.classList.remove('to-bottom');
                timetableArrow.classList.add('fixed');
            }
            else if (window.pageYOffset >= timetableTableOffsetBottom - .9 * windowHeight) {
                timetableArrow.classList.remove('fixed');
                timetableArrow.classList.add('to-bottom');
            }else{
                timetableArrow.classList.remove('fixed');
                timetableArrow.classList.remove('to-bottom');
            }
        });

        // timetableArrow.addEventListener('click', function() {
        //     timetableTable.style.transform = "translateX(-" + timetableTable.style.transform + timetableCellWidth + "px)";
        // });

        $('#timetable-arrow').click(function () {
            var leftPos = $('#timetable-wrap').scrollLeft();
            $("#timetable-wrap").animate({
                scrollLeft: leftPos + timetableCellWidth
            }, 300);
        });
    }

    function newsSidebar(){
        $('#news .sidebar .slider').slick({
            rows: 4,
            dots: false,
            prevArrow: $('.slider-nav .prev'),
            nextArrow: $('.slider-nav .next'),
            infinite: false,
            accessibility: false,
            slidesPerRow: 1,
            responsive: [
                {
                breakpoint: 767,
                  settings: {
                    slidesPerRow: 2,
                  }
                },
                {
                  breakpoint: 479,
                  settings: {
                    slidesPerRow: 1,
                  }
                },

              ]
        });
    }

    function modal(){

        var modal = document.getElementById('modal');
        var modalTrial = document.getElementById('modal-trial');
        if (! modal) {
            return false;
        }
        var id;

        $(document).keyup(function(e) {
             if (e.keyCode == 27) {
                closeModal();
            }
        });

        $('body').on('click', '.tooltip-btn', function(e){
            e.preventDefault();
            $('.ajax-loader').show();
            modal.classList.add('visible');
            body.classList.add('modal-opened');

            id = $(this).data('id');

            $.ajax({
                type: "POST",
                url: "../../../wp-admin/admin-ajax.php",
                dataType: 'html',
                data: ({ action: 'loadPost', id: id}),

                success: function(data)
                {
                    $(data).appendTo('#modal .modal-content');
                    presentationTile();
                    $('.ajax-loader').hide();
                },
                error: function(data)
                {
                    alert("Your browser broke!");
                    return false;
                }

            });
        });

        $('#modal .close-modal').click(function(){
            closeModal();
        });

        function closeModal(){
            modal.classList.remove('visible');
            document.querySelector('#modal .modal-content').innerHTML = '';
            body.classList.remove('modal-opened');
        }
    }

    function trialModal(){

        var modalTrial = document.getElementById('modal-trial');
        if (! modalTrial) {
            return false;
        }
        var id;

        $(document).keyup(function(e) {
             if (e.keyCode == 27) {
                closeModal();
            }
        });

        $('body').on('click', 'a[href="#trial"]', function(e){

            modalTrial.classList.add('visible');
            body.classList.add('modal-opened');

        });

        $('#modal-trial .close-modal').click(function(){
            closeModal();
        });

        function closeModal(){
            modalTrial.classList.remove('visible');
            body.classList.remove('modal-opened');
        }
    }

    function priceTable(){
        var tables = document.querySelectorAll('table');
        var outerHTML;
        for (var i = tables.length - 1; i >= 0; i--) {
            outerHTML = tables[i].outerHTML;
            tables[i].outerHTML = "<div class='table-responsive'>" + outerHTML + "</div>";
        }
    }

    function movieModal(){
        var movieModal = document.getElementById('movie-modal');

        if (! movieModal) {
            return false;
        }

        $('.movie-btn').click(function(e){
            e.preventDefault();
            movieModal.classList.add('visible');
            body.classList.add('modal-opened');
        });

        $('#movie-modal .close-modal').click(function(e){
            e.preventDefault();
            closeModal();
        });

        $(document).keyup(function(e) {
             if (e.keyCode == 27) {
                closeModal();
            }
        });

        function closeModal(){
            var el_src = $('#player').attr("src");
            $('#player').attr("src",el_src);
            movieModal.classList.remove('visible');
            body.classList.remove('modal-opened');
        }
    }

    function smoothScroll() {
        $('a[href*="#"]:not([href="#"]):not(.no-scroll)').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - 61
                    }, 1000);
                    return false;
                }
            }
        });
    }

    window.addEventListener('load', function() {



        homepage();
        presentationTile();
        pageNav();
        timetable();
        newsSidebar();
        modal();
        trialModal();
        priceTable();
        movieModal();
        smoothScroll();
    });

    $('.menu-toggle').click(function() {
        $('#nav').slideToggle();
    });

})(jQuery);
